<template>
  <div id="introjs-component" />
</template>

<script>
import introJs from 'intro.js'
import {storeToRefs} from 'pinia'
import {useCmsService} from '@/composables/cms_service'
import {useGuidedToursStore} from '@/store/guided-tours'

function sanitizeConfig(parsed, element, showWarnings) {
  return parsed.steps.map((step) => {
    const selector = step?.element ? document.querySelector(step.element) : null
    if (!selector) {
      if (typeof (step.element) !== 'undefined' && showWarnings) {
        // eslint-disable-next-line no-console
        console.warn(`GuidedTours: "${element.Name}" could not find element "${step.element}"`)
      }
      return step
    }
    return {...step, element: selector}
  })
}

export default defineComponent({
  setup() {
    const {getCmsEntityList} = useCmsService()
    const {completedTours} = storeToRefs(useGuidedToursStore())
    const {addCompletedTour} = useGuidedToursStore()
    return {
      getCmsEntityList,
      completedTours,
      addCompletedTour
    }
  },
  async mounted() {
    const tours = await this.getCmsEntityList('guided-tours', this.$i18n.locale)

    if (tours && tours.length > 0) {
      for (const element of tours) {
        const parsed = element.Config
        const mappedSteps = sanitizeConfig(parsed, element, this.$config.public.environment === 'DEV' || this.$config.public.environment === 'STG')

        if (!this.completedTours.some((tour) => tour === element.Name)) {
          await new Promise((resolve) => {
            setTimeout(() => {
              introJs()
                .setOptions({steps: mappedSteps})
                .onexit(() => {
                  this.addCompletedTour(element.Name)
                  resolve()
                })
                .oncomplete(() => {
                  this.addCompletedTour(element.Name)
                  resolve()
                })
                .start()
            }, 1000)
          })
        }
      }
    }
  }
})
</script>

<style>
#introjs-component {
  display: none;
}
</style>
