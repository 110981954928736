<template>
  <div
    v-if="content !== null"
    class="home-hero"
  >
    <div
      class="home-hero__full-size-image-wrapper"
      v-show="content.FSTwave !== null"
    >
      <ResponsiveImage :picture="content.Image" />
      <div class="home-hero__full-size-image-wrapper__wave pure-u-1">
        <WaveWhite
          class="w-full"
          v-show="content.FSTwave"
          :alt="$tv('waveAltText', 'home')"
        />
      </div>
    </div>
    <div
      class="home-hero__text"
      :class="{'home-hero__text--light': content.TextAppearance === 'Light'}"
    >
      <div
        v-if="content.Subline"
        class="home-hero__text__subline"
        v-text="content.Subline"
      />
      <div>
        <h1
          v-if="content.Header"
          class="h1 h1--underline"
          v-text="content.Header"
        />
      </div>
      <div class="home-hero__text__btn-container">
        <a
          v-if="content.Link"
          :href="content.Link"
          class="home-hero__text__btn-container__btn k-button k-button-md k-button-solid k-button-solid-primary"
        >
          <span v-text="buttonText" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import ResponsiveImage from '~/components/responsive-image'
import WaveWhite from '~/assets/images/wave-white.svg?component'

export default {
  components: {ResponsiveImage, WaveWhite},
  props: {
    content: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data() {
    return {
      BASE_URL: useRuntimeConfig().public.BASE_URL
    }
  },
  computed: {
    buttonText () {
      if (this.content?.ButtonLabel) {
        return this.content.ButtonLabel
      }
      return this.$tv('learnMore', 'home')
    }
  }
}
</script>

<style lang="scss">
  .home-hero {
    position: relative;

    &__full-size-image-wrapper {
      position: relative;

      picture {
        display: block;
      }

      svg.icon--svg {
        width: 100%;
      }

      &__wave {
        position: absolute;
        bottom: 0;

        img {
          max-width: 100%;
        }

        @include breakpoint-down($md) {
          display: none;
        }
      }
    }

    &__text {
      width: calc(100% - 2rem);
      color: $color__primary;

      @include breakpoint-up($between_md_lg) {
        position: absolute;
        top: .625rem;
        right: 2rem;
        width: 30rem;
      }

      @include breakpoint-down($between_md_lg) {
        font-size: $base-text-font-size;
        padding: $default-margin-padding-width;
      }

      @include breakpoint-between($md, $between_md_lg) {
        font-size: $base-text-font-size;
        padding: $default-margin-padding-width 2rem;
      }

      &__subline {
        text-transform: uppercase;
      }

      h1 {
        margin-top: .75rem;

        @include breakpoint-down($md) {
          font-size: 1.0625rem;
        }
      }

      &__btn-container {
        display: inline-flex;
        justify-content: flex-end;

        &__btn.k-button {
          margin-top: 2rem;

          .k-button-solid-primary {
            color: $color__fst_gray__100;
          }

          @include breakpoint-down($between_md_lg) {
            margin-top: 1rem;
          }
        }

        @include breakpoint-down($between_md_lg) {
            display: flex;
            justify-content: flex-end;
            padding-right: 2rem;
          }

          @include breakpoint-down($md) {
            padding-right: 0;
          }
      }

      @include breakpoint-up($between_md_lg) {
        &--light {
          color: $color__white;

          h1 {
            color: $color__white;
          }
        }
      }
    }
  }
</style>
