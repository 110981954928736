import {defineStore} from 'pinia'

export const useGuidedToursStore = defineStore('GuidedToursModule', {
  persist: {
    storage: persistedState.localStorage,
    paths: ['completedTours']
  },
  state: () => ({
    completedTours: []
  }),
  getters: {},
  actions: {
    addCompletedTour(tourName) {
      // eslint-disable-next-line eqeqeq
      if (tourName != null && !this.completedTours.some((c) => c === tourName)) {
        this.completedTours.push(tourName)
      }
    },
    resetCompletedTours() {
      this.completedTours = []
    }
  }
})
