import {useCmsService} from '@/composables/cms_service'

export const useStartPageService = () => {

  const {getCmsEntitySingle} = useCmsService()

  const getStartPage = async (lang, region, loggedIn) => {
    const startPage = await getCmsEntitySingle('start-pages', lang, [
      {field: 'LoggedIn', operator: 'eq', value: loggedIn}
    ])
    if (!startPage || startPage.length <= 0) {
      return null
    }
    return startPage.filter((ca) => ca.region === null || ca.region.RegionShortName === region)[0]
  }

  return {getStartPage}
}
