<template>
  <a
    v-if="content !== null"
    class="card-tile cardTile"
    :class="'card-tile--' + content.TileStyle"
    :href="content.TileLink"
  >
    <div
      class="card-tile__headline"
      v-text="content.TileHeadline"
    />
    <Markdown
      :text="content.TileContent"
      class="card-tile__content"
    />
    <NavigationChevronRight class="card-tile__arrow" />
  </a>
</template>
<script>
import NavigationChevronRight from '~/assets/icons/navigation_chevron_right.svg?component'

export default {
  components: {NavigationChevronRight},
  props: {
    content: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.card-tile {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 22.25rem;
  height: 17.9375rem;
  padding: 1.125rem;
  margin: 1.25rem 0 0 1.25rem;
  box-shadow: 0 .1875rem .375rem $color--card__shadow;

  @include breakpoint-down($md) {
    width: 100%;
  }

  &--Blue {
    background-color: $color__primary;
    color: $color-_white;
  }

  &--White {
    background-color: $color__white;
    color: $color__text__default;
  }

  .card-tile__headline {
    font-size: map-get($base-text-subtitle-medium, font-size );
    font-weight: map-get($base-text-subtitle-medium, font-weight );
    margin-bottom: $default-margin-padding-width;
    overflow: hidden;

    @include breakpoint-down($md) {
      font-size: map-get($base-text-subtitle-medium, font-size );
    }
  }

  .card-tile__content {
    max-width: 70%;
    overflow: hidden;
    font-weight: $base-text-font-weight-light;
    flex-grow: 1;
  }

  .card-tile__arrow {
    position: absolute;
    right: .3rem;
    bottom: .3rem;
    font-size: 3.75rem;
    color: $color__default;
  }
}
</style>
